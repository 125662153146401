import React from 'react';
import { useRouter } from 'next/router';
import { GlobalHeader, GlobalFooter } from '@/components/Layouts';
import { useConfigProvider } from '@/context/ConfigProvider';
import classNames from 'classnames';
// import SellOnPietraDialog from '../components/Layouts/SellOnPietraDialog';
import { useToggle } from 'react-use';
import './index.less';
import { AppSettings } from '@/shared/app-common';
import { useTranslation } from 'next-i18next';

interface LayoutProps {
  /**
   * Please use next/head
   */
  head?: React.ReactNode;
  children: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
}

// export async function getServerSideProps() {
//   const res = await fetch(
//     `https://api.pietrastudio.com/commons/business-settings/DISPLAY/CATEGORY_NAV_DROPDOWN_ITEMS`,
//   );
//   console.log('res:', res);
//   return {
//     categoryNavDropdownItems: [],
//   };
// }

const Layout = ({ children, head, showHeader = true, showFooter = true }: LayoutProps) => {
  const { pathname } = useRouter();
  const { isMobile, isShopPage } = useConfigProvider();
  // const [open, toggle] = useToggle(false);
  const { t } = useTranslation('common');

  // app sub title
  const getNavItems = () => {
    switch (AppSettings.type) {
      case '1':
        return [
          {
            text: t('FeaturedBooks'),
            path: '/recommended-books',
          },
          {
            text: t('guide'),
            path: '/category/guide',
          },
          {
            text: t('insight'),
            path: '/category/insight',
          },
          {
            text: t('poems'),
            path: '/category/poems',
          },
          {
            text: t('stories'),
            path: '/category/stories',
          },
        ];
      case '2':
        return [
          {
            text: t('guide'),
            path: '/category/guide',
          },
          {
            text: t('insight'),
            path: '/category/insight',
          },
        ];
      case '3':
      case '5':
        return [
          {
            text: t('pregnancy'),
            path: '/category/guide',
          },
          {
            text: t('parenting'),
            path: '/category/insight',
          },
        ];
      case '4':
        return [
          {
            text: t('theArtWorld'),
            path: '/category/guide',
          },
          {
            text: t('pinkGuide'),
            path: '/category/insight',
          },
        ];
      default:
        [];
    }
  };

  return (
    <div className={'layout'}>
      {head}
      {showHeader && (
        <header className={'header'}>
          <GlobalHeader
            isMobile={isMobile}
            isMarketplaceHome={pathname === '/'}
            isShopPage={isShopPage}
            navItems={[
              {
                text: t('home'),
                path: '/',
              },
              {
                text: 'GPTs',
                path: '/gpts',
              },

              ...getNavItems(),
              // {
              //   text: 'Guide',
              //   path: '/category/guide',
              // },
              // {
              //   text: 'Insight',
              //   path: '/category/insight',
              // },
              // {
              //   text: 'Discover',
              //   dropdownItems: menus || [],
              // },
              // {
              //   text: 'Sell on Pietra',
              //   onClick: () => {
              //     toggle();
              //   },
              // },
            ]}
          />
          {/* <SellOnPietraDialog isMobile={isMobile} isModalOpen={open} onCancel={toggle} /> */}
        </header>
      )}
      <main
        className={classNames('main', {
          mobile: isMobile,
        })}
      >
        {children}
      </main>
      {showFooter && (
        <footer className={'footer'}>
          <GlobalFooter />
        </footer>
      )}
    </div>
  );
};
export default Layout;
