import Link from 'next/link';
import { Tooltip } from 'antd';
import { useRouter } from 'next/router';
import styles from './index.module.less';
import { AllSupportLanguages, CurrentLanguage } from '@/shared/language';
import { useConfigProvider } from '@/context/ConfigProvider';
import { isDrFans } from '@/shared/app-common';
// import Cookies from 'js-cookie';
// import { useTranslation } from 'next-i18next';

const ChangeLanguage = () => {
  // const { i18n } = useTranslation();
  const { isMobile } = useConfigProvider();

  const router = useRouter();
  const currentLocale = router.locale;

  const currentColor = (color: string) => {
    return color === currentLocale ? 'red' : '#000';
  };

  const items = () => {
    return (
      <div className={styles.listContainer} style={{ color: '#000' }}>
        {AllSupportLanguages.map((item) => {
          return (
            <div style={{ padding: 5 }} key={item.code}>
              <Link
                href={router.asPath}
                locale={item.code}
                style={{ color: currentColor(item.code) }}
                // onClick={() => {
                //     Cookies.set('preferredLanguage', item.code)
                //     const cookieValue = Cookies.get('preferredLanguage');
                //     console.log(cookieValue);
                // }}
              >
                {item.name}
              </Link>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.mainContainer}>
      <Tooltip title={items} color={'#FFF'} placement="bottomRight">
        <div className={styles.content}>
          <img
            src={
              isDrFans
                ? '/images/layouts/language_icon.png'
                : '/images/layouts/language_icon_dark.png'
            }
            alt="language"
          />
          {!isMobile && <span>{CurrentLanguage(currentLocale).name}</span>}
        </div>
      </Tooltip>
    </div>
  );
};

export default ChangeLanguage;
