import { KeyboardEvent, useMemo, useRef, useState } from 'react';
import { useUserProvider } from '@/context/UserProvider';
import classNames from 'classnames';
import styles from './index.module.less';
import { useConfigProvider } from '@/context/ConfigProvider';
import { useRouter } from 'next/router';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useTranslation } from 'next-i18next';

const HeaderSearch = ({
  size = 'small',
  className,
}: {
  size?: 'large' | 'small';
  className?: string;
}) => {
  // const { open, total, onOpenDrawer } = useUserProvider()
  // const { isMobile } = useConfigProvider();

  const { t } = useTranslation('common');
  const iconSrc = useMemo(() => {
    return size === 'large'
      ? '/images/layouts/cart-icon-120.png'
      : '/images/layouts/cart-icon-50.png';
  }, [size]);
  const router = useRouter();
  const [inptWith, setInptWith] = useState(142);
  const [value, setValue] = useState<string>();
  const ref = useRef(null);

  const searchMarketplace = (e: any) => {
    if (value != undefined) {
      router.push(`/search?value=${value}`);
      setTimeout(() => {
        setValue('');
        if (ref.current) {
          ref.current.blur();
        }
      }, 100);
    }
  };

  return (
    <div className={classNames(styles.shoppingCartWarp, className)}>
      <Input
        ref={ref}
        className={styles.searchInput}
        style={{ width: `${inptWith}px` }}
        placeholder={`${t('search')}...`}
        suffix={<SearchOutlined />}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            searchMarketplace(e);
          }
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        value={value}
        onFocus={() => {
          setInptWith(195);
        }}
        onBlur={() => {
          setInptWith(142);
        }}
      />

      {/* <input
        onChange={(e) => {
          setValue(e.target.value)
        }}
        className={styles.searchInput}
        placeholder="Search..."
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            searchMarketplace();
          }
        }}
      /> */}
    </div>
  );
};
export default HeaderSearch;
